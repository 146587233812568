import React from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'
import IconWrapper from '@/utils/IconWrapper'
import Twitter from '@/icons/twitter.svg'
import Facebook from '@/icons/facebook.svg'
import LinkedIn from '@/icons/linkedin.svg'
import Instagram from '@/icons/instagram.svg'
import YouTube from '@/icons/youtube.svg'

function SocialItem({ href, icon, text }) {
	return (
		<li className="flex w-5 h-5 mr-4">
			<a
				href={href}
				rel="noopener noreferrer"
				target="blank"
				className="flex items-center w-full text-subtlegrey hover:text-darkgrey"
			>
				<IconWrapper wrapperClassName="w-full" icon={icon} />
				<span className="sr-only">{text}</span>
			</a>
		</li>
	)
}

function Social({ className }) {
	return (
		<ul className={classNames('flex', className)}>
			<SocialItem
				href="https://twitter.com/ultraleap"
				icon={Twitter}
				text="twitter"
			/>
			<SocialItem
				href="https://www.linkedin.com/company/ultraleap"
				icon={LinkedIn}
				text="linkedin"
			/>
			<SocialItem
				href="https://www.facebook.com/ultraleap/"
				icon={Facebook}
				text="facebook"
			/>
			<SocialItem
				href="https://www.instagram.com/ultraleaplife/"
				icon={Instagram}
				text="instagram"
			/>
			<SocialItem
				href="https://www.youtube.com/channel/UCN1OKsIVyMxskq0apQA8Y1A"
				icon={YouTube}
				text="youtube"
			/>
		</ul>
	)
}

Social.propTypes = {
	className: string
}

export default Social
