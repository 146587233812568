import React, { memo, useMemo, forwardRef } from 'react'
import { func, oneOfType, string, node, element, object } from 'prop-types'
import classNames from 'classnames'
import Node from '@/utils/Node'
const toNum = num => parseFloat(num, 10)

const IconWrapper = forwardRef(
	(
		{ icon, className, wrapperClassName, as = 'span', label, ...props },
		ref
	) => {
		const svg = React.createElement(icon, {
			className: classNames(
				className,
				'absolute inset-0 w-full h-full m-auto',
				{
					'fill-current': !className
				}
			),
			'aria-hidden': true
		})
		const { width, height, viewBox } = svg.props

		const ratio = useMemo(() => {
			const size = viewBox ? viewBox.split(' ').slice(2) : [width, height]
			return size
				.map(val => toNum(val))
				.reduceRight((y, x) => `${(y / x) * 100}%`)
		}, [height, viewBox, width])

		return (
			<Node
				as={as}
				ref={ref}
				{...props}
				className={classNames(wrapperClassName, 'block')}
			>
				<span
					className="relative w-full block h-0 w-full"
					style={{ paddingTop: ratio }}
				>
					{svg}
					{label && <span className="sr-only">{label}</span>}
				</span>
			</Node>
		)
	}
)

IconWrapper.propTypes = {
	className: string,
	wrapperClassName: string,
	as: oneOfType([func, node, element, string, object]),
	label: string,
	icon: oneOfType([func, node, element]).isRequired
}

export default memo(IconWrapper)
