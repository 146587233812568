/* eslint-disable react/prop-types */
import React from 'react'
import raf from 'raf-throttle'
import objectFitImages from 'object-fit-images';
import Layout from '@/container/Layout'
import { NavProvider } from '@/container/NavContext'
import { StickyNavProvider } from '@/container/StickyNavContext'
import { TabProvider } from '@/container/TabContext'


export const onClientEntry = async () => {
	const vh = window.innerHeight * 0.01
	// Then we set the value in the --vh custom property to the root of the document
	document.documentElement.style.setProperty('--vh', `${vh}px`)

	objectFitImages()

	window.addEventListener(
		'resize',
		raf(() => {
			document.documentElement.style.setProperty(
				'--vh',
				`${window.innerHeight * 0.01}px`
			)
		})
	)

	window.addEventListener('DOMContentLoaded', function() {
		function getCookie(name) {
			const value = `; ${document.cookie}`;
			const parts = value.split(`; ${name}=`);
			if (parts.length === 2) return parts.pop().split(';').shift();
		}

		if (getCookie('ultraleapCookie')) {
			// Add class so we can move chatbot
			document.body.classList.add('cookie-consent-accepted');
		}
	});

	if (typeof IntersectionObserver === `undefined`) {
		await import(`intersection-observer`)
	}
}

export const wrapRootElement = ({ element }) => {
	return (
		<NavProvider>
			<TabProvider>
				<StickyNavProvider>{element}</StickyNavProvider>
			</TabProvider>
		</NavProvider>
	)
}

export const wrapPageElement = ({ element, props }) => {
	// props provide same data to Layout as Page element will get
	// including location, data, etc - you don't need to pass it
	return <Layout {...props}>{element}</Layout>
}

export const shouldUpdateScroll = ({
	routerProps: { location },
	getSavedScrollPosition
}) => {
	if (!location.hash) {
		if (location.action === 'PUSH') {
			window.setTimeout(() => {
				window.scroll({
					top: 0,
					behavior: 'smooth'
				})
			}, 300)
		} else {
			const savedPosition = getSavedScrollPosition(location)
			window.setTimeout(
				() => window.scrollTo(...(savedPosition || [0, 0])),
				300
			)
		}
	} else {
		window.setTimeout(() => {
			const elm = document.querySelector(location.hash)
			const newsHash = location.hash === '#news-filters'
			const howItWorksHash = location.hash === '#how-it-works'
			if (elm && (newsHash || howItWorksHash)) {
				const { top } = elm.getBoundingClientRect()
				const offset = newsHash ? 100 : 0

				window.scroll({
					top: top + window.pageYOffset - offset,
					behavior: 'smooth'
				})
			}
		}, 700)

		return false
	}

	return true
}
