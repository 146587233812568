import React from 'react'
import { string, shape, arrayOf, oneOfType, number } from 'prop-types'
import Link from '@/utils/Link'
import useTransformMenuProps from '@/hooks/useTransformMenuProps'

function FooterLegalNav({ menu }) {
	const menuItems = useTransformMenuProps(process.env.SOURCE, menu)

	return (
		<ul className="flex items-center lg:block">
			{menuItems.map(item => (
				<li
					className="mx-2 text-sm text-primary lg:text-right lg:mx-0"
					key={item.db_id}
				>
					<Link to={item.url}>{item.title}</Link>
				</li>
			))}
		</ul>
	)
}

FooterLegalNav.propTypes = {
	menu: shape({
		items: arrayOf(
			shape({
				title: string.isRequired,
				url: string.isRequired,
				db_id: oneOfType([string, number]).isRequired
			})
		).isRequired
	}).isRequired
}

export default FooterLegalNav
