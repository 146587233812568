import React from 'react'
import { string, arrayOf, shape, number } from 'prop-types'
import classNames from 'classnames'
import Link from '@/utils/Link'
import useTransformMenuProps from '@/hooks/useTransformMenuProps'

function FooterMenu({ items, heading, className }) {
	const menuItems = items ? useTransformMenuProps(process.env.SOURCE, { items }) : false
	return (
		<div className={classNames(className)}>
			<h3 className="mb-8 text-h4-fluid font-museoSans text-primary">{heading}</h3>
			{menuItems && (
				<ul>
					{menuItems.map(({ title, db_id, url }) => (
						<li className="mb-4 last:mb-0" key={db_id}>
							<Link className="text-sm text-darkgrey font-museoSansBold pardotTrackClick" to={url}>
								{title}
							</Link>
						</li>
					))}
				</ul>
			)}
		</div>
	)
}

FooterMenu.propTypes = {
	heading: string.isRequired,
	className: string,
	items: arrayOf(
		shape({
			title: string.isRequired,
			db_id: number.isRequired,
			url: string.isRequired
		})
	)
}

export default FooterMenu
