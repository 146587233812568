import React, { useState, createContext } from 'react'
import { node } from 'prop-types'

export const TabContext = createContext()

export function TabProvider({ children }) {
	const [tabIndex, setTabIndex] = useState({ value: null })

	return (
		<TabContext.Provider value={{ tabIndex, setTabIndex }}>
			{children}
		</TabContext.Provider>
	)
}

TabProvider.propTypes = {
	children: node.isRequired
}
