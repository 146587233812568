import React from 'react'
import { string, shape, arrayOf, number } from 'prop-types'
import Link from '@/utils/Link'
import Button from '@/components/global/Button'
import Social from './Social'
import FooterLegalNav from './FooterLegalNav'
import FooterMenu from './FooterMenu'
import IconWrapper from '@/utils/IconWrapper'
import Logo from '@/icons/logo.svg'

function Footer({ menu, legal, isLandingPage }) {

	return (
		<footer className="w-full border-t">
			<div className="px-8 py-10 wrapper md:py-20">
				{!isLandingPage ? (
					<div className="flex flex-col md:flex-wrap md:flex-row">
						{menu.items.map(item => (
							<FooterMenu
								key={item.db_id}
								className="mb-8 mg:mb-0 md:w-1/3 lg:w-1/4"
								heading={item.title}
								items={item.child_items}
							/>
						))}

						<div className="flex flex-col items-center w-full mt-8 lg:items-end lg:w-1/4 lg:mt-0">
							<IconWrapper
								icon={Logo}
								label="Ultraleap"
								wrapperClassName="block w-full max-w-logo-fluid mb-8 lg:ml-auto"
							/>
							<Social className="mb-8" />

							<Button
								as={Link}
								theme="primary"
								className="mb-8 font-bold"
								to="/contact-us/"
							>
								Contact us
							</Button>
							<FooterLegalNav menu={legal} />
						</div>
					</div>
				) : (
					<div className="flex content-center justify-between">
						<IconWrapper
							icon={Logo}
							label="Ultraleap"
							wrapperClassName="block w-full max-w-logo-fluid"
							as={Link}
							to="/"
						/>
						<div className="flex items-center">
							<Button
								as={Link}
								theme="primary"
								className="mr-4 font-bold"
								to="/contact-us/"
							>
								Contact us
							</Button>
							<FooterLegalNav menu={legal} />
						</div>
					</div>
				)}
			</div>
		</footer>
	)
}

Footer.propTypes = {
	menu: shape({
		items: arrayOf(
			shape({
				title: string.isRequired,
				db_id: number.isRequired,
				url: string.isRequired,
				child_items: arrayOf(
					shape({
						title: string.isRequired,
						db_id: number.isRequired,
						url: string.isRequired
					})
				)
			})
		).isRequired
	}).isRequired,
	legal: shape({
		items: arrayOf(
			shape({
				title: string.isRequired,
				db_id: number.isRequired,
				url: string.isRequired
			})
		).isRequired
	}).isRequired
}

export default Footer

// <footer>

// </footer>
