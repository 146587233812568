import React, { useState, useEffect, useRef, createContext } from 'react'
import { node } from 'prop-types'
import {
	disableBodyScroll,
	enableBodyScroll,
	clearAllBodyScrollLocks
} from 'body-scroll-lock'

export const NavContext = createContext()

export function NavProvider({ children }) {
	const [isOpen, setOpen] = useState(false)
	const $body = useRef(null)

	useEffect(() => {
		if (isOpen) {
			disableBodyScroll($body.current)
		} else {
			enableBodyScroll($body.current)
		}

		return () => {
			clearAllBodyScrollLocks()
		}
	}, [isOpen])

	return (
		<NavContext.Provider value={{ isOpen, setOpen }}>
			<div ref={$body}>{children}</div>
		</NavContext.Provider>
	)
}

NavProvider.propTypes = {
	children: node.isRequired
}
