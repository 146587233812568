import React from 'react'
import { string, shape, object, number, oneOf, oneOfType } from 'prop-types'
import GatsbyImage from 'gatsby-image'
import classNames from 'classnames'

function Image({
	type,
	style,
	alt_text,
	width,
	height,
	source_url,
	title,
	imgStyle,
	className,
	...rest
}) {
	style = {
		minWidth: '128px',
		...style
	}
	if (type === 'gatsby') {
		return (
			<GatsbyImage
				alt={alt_text || title}
				width={width}
				height={height}
				style={style}
				imgStyle={imgStyle}
				fluid={rest}
			/>
		)
	}

	return (
		<img
			className={classNames({ 'object-cover': !className }, className)}
			src={source_url}
			width={width}
			height={height}
			style={style}
			alt={alt_text || title}
			loading="lazy"
		/>
	)
}

Image.propTypes = {
	type: oneOf(['gatsby', 'basic']),
	style: shape({
		width: oneOfType([string, number]),
		height: oneOfType([string, number])
	}),
	alt_text: string,
	title: string,
	source_url: string,
	width: oneOfType([string, number]),
	height: oneOfType([string, number]),
	// eslint-disable-next-line react/forbid-prop-types
	imgStyle: object,
	className: string
}

export default Image
