import React, { useContext, useState } from 'react'
import { string, shape, arrayOf, number } from 'prop-types'
import classNames from 'classnames'

import Link from '@/utils/Link'
import Image from '@/utils/Image'

import { NavContext } from '@/container/NavContext'
import IconWrapper from '@/utils/IconWrapper'
import Arrow from '@/icons/arrow.svg'
import styles from './MegaMenu.module.css';

const MenuItem = ({
	title,
	url,
	child_items,
	toggled,
	handleToggle
}) => {

    const { isOpen, setOpen } = useContext(NavContext)
    
	const onClick = () => {
        if (isOpen) setOpen(false)
	}
    
	return (
		<li
			className={ classNames('text-2xl md:text-base text-center', styles.item) }
		>
			<Link
				onClick={ onClick }
				to={ url }
				className={ classNames(
					'block py-5 overflow-hidden md:px-5 lg:px-8 md:relative group font-museoSans tracking-wide text-lg',
					styles.topLink,
					toggled ? styles.topLinkToggled : ''
				) }
				activeClassName="child:x-0 child:opacity-100"
				partiallyActive
				style={ { maxHeight: '81px' } }
			>
				{ title }
				<span className={ classNames("absolute bottom-2 left-0 hidden w-full h-2 opacity-100 md:block bg-primary -x-101 group-hover:x-0 group-focus:x-0 group-hover:opacity-100 transition-both", styles.bar) } />
			</Link>

			{ !child_items || !child_items.length ? null :
				<>
					<div className={ styles.toggleWrapper }>
						<button
							className={ toggled ? styles.toggleOpen : styles.toggle }
							onClick={ handleToggle }
						>
							<IconWrapper
								className={ styles.arrow }
								label="Toggle submenu"
								icon={ Arrow }
							/>
						</button>
					</div>

					<div 
                        className={ toggled ? styles.backgroundOpen : styles.background }
                    >
						<ul
							className={ classNames('md:wrapper', styles.ul) }
						>
							{ child_items.map(item => (
								<li className={ styles.li } key={ item.db_id }>
									<div className={ item.title && item.title !== '#' ? styles.liContents : styles.liContentsFullHeight }>

										{ !item.image ? null :
											<Image
												style={ { minWidth: '0px' } }
												className={ classNames("object-contain w-auto mx-auto my-auto", styles.image) }
												{ ...item.image }
											/>
										}

										{ item.title && item.url && item.title !== '#' &&
											<Link to={ item.url } className={ classNames(styles.link, 'font-museoSans text-base tracking-wide') }>
												{ item.title }
											</Link>
										}

										{ item.title && !item.url &&
											<span className={ classNames(styles.link, 'font-museoSans text-base tracking-wide') }>
												{ item.title}
											</span>
										}


                                        <ul className={ styles.subUl }>
                                            { !item.child_items ? null :
                                                item.child_items.map(subItem => (
                                                    <li className={ styles.subLi } key={ subItem.db_id }>
                                                        <Link to={ subItem.url } className={ classNames(styles.subLink, 'font-museoSans text-base tracking-wide') }>
                                                            { subItem.title }
                                                        </Link>
                                                    </li>
                                                ))
                                            }
                                        </ul>
									</div>
								</li>
							)) }
						</ul>
					</div>
				</>
			}
		</li>
	)
}

MenuItem.propTypes = {
	title: string.isRequired,
	url: string.isRequired,
	child_items: arrayOf(
		shape({
			title: string.isRequired,
			url: string.isRequired,
			db_id: number.isRequired,
		})
	)
}

export default MenuItem;