import React, { useState, createContext } from 'react'
import { node } from 'prop-types'

export const StickyNavContext = createContext()

export function StickyNavProvider({ children }) {
	const [isStickyNav, setStickyNav] = useState(true)

	return (
		<StickyNavContext.Provider value={{ isStickyNav, setStickyNav }}>
			{children}
		</StickyNavContext.Provider>
	)
}

StickyNavProvider.propTypes = {
	children: node.isRequired
}
