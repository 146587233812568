import React from 'react'
import {
	string,
	node,
	oneOf,
	oneOfType,
	func,
	element,
	object
} from 'prop-types'
import classNames from 'classnames'
import Node from '@/utils/Node'

function Button({
	as = 'button',
	children,
	theme = 'primary',
	className,
	textColor,
	track = true,
	...rest
}) {
	return (
		<Node
			as={as}
			className={classNames(
				className,
				{
					'bg-secondary text-white': theme === 'secondary',
					'bg-primary text-white': theme === 'primary',
					'bg-white': theme === 'white',
					'border-2 border-solid border-white bg-transparent':
						theme === 'transparent',
					'text-white': !textColor && theme !== 'white',
					'text-secondary': !textColor && theme === 'purple',
					// [textColor]: !!textColor
					'pardotTrackClick': track
				},
				'transition-all inline-flex items-center justify-center  text-sm text-left font-museoSansRegular py-2 px-4 hover:px-8 relative rounded-full'
			)}
			{...rest}
		>
			<span>{children}</span>
		</Node>
	)
}

Button.propTypes = {
	as: oneOfType([string, node, func, element, object]),
	children: node,
	className: string,
	textColor: string,
	theme: oneOf(['secondary', 'primary', 'white', 'purple', 'transparent'])
}

export default Button
