import { useMemo, useCallback } from 'react'

function useTransformMenuProps(baseUrl, menu) {
	const transformUrl = useCallback(
		url => {
			if (url.includes(baseUrl)) {
				const slug = url.split(baseUrl)[1]

				return slug
			}

			return url
		},
		[baseUrl]
	)

	const mapMenu = useCallback(
		({ url, child_items, ...item }) => {
			if (!child_items) {
				return {
					...item,
					url: transformUrl(url)
				}
			}

			return {
				...item,
				url: transformUrl(url),
				child_items: child_items.map(mapMenu)
			}
		},
		[transformUrl]
	)

	const menuItems = useMemo(() => {
		return menu.items.map(mapMenu)
	}, [mapMenu, menu.items])

	return menuItems
}

export default useTransformMenuProps
