// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-case-study-index-js": () => import("./../../../src/templates/case-study/index.js" /* webpackChunkName: "component---src-templates-case-study-index-js" */),
  "component---src-templates-company-about-js": () => import("./../../../src/templates/company/About.js" /* webpackChunkName: "component---src-templates-company-about-js" */),
  "component---src-templates-company-index-js": () => import("./../../../src/templates/company/index.js" /* webpackChunkName: "component---src-templates-company-index-js" */),
  "component---src-templates-company-join-js": () => import("./../../../src/templates/company/Join.js" /* webpackChunkName: "component---src-templates-company-join-js" */),
  "component---src-templates-company-news-js": () => import("./../../../src/templates/company/News.js" /* webpackChunkName: "component---src-templates-company-news-js" */),
  "component---src-templates-company-news-post-js": () => import("./../../../src/templates/company/NewsPost.js" /* webpackChunkName: "component---src-templates-company-news-post-js" */),
  "component---src-templates-contact-index-js": () => import("./../../../src/templates/contact/index.js" /* webpackChunkName: "component---src-templates-contact-index-js" */),
  "component---src-templates-enterprise-index-js": () => import("./../../../src/templates/enterprise/index.js" /* webpackChunkName: "component---src-templates-enterprise-index-js" */),
  "component---src-templates-enterprise-use-case-js": () => import("./../../../src/templates/enterprise/UseCase.js" /* webpackChunkName: "component---src-templates-enterprise-use-case-js" */),
  "component---src-templates-haptics-index-js": () => import("./../../../src/templates/haptics/index.js" /* webpackChunkName: "component---src-templates-haptics-index-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-licensing-index-js": () => import("./../../../src/templates/licensing/index.js" /* webpackChunkName: "component---src-templates-licensing-index-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-partner-index-js": () => import("./../../../src/templates/partner/index.js" /* webpackChunkName: "component---src-templates-partner-index-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */),
  "component---src-templates-research-research-program-js": () => import("./../../../src/templates/research/ResearchProgram.js" /* webpackChunkName: "component---src-templates-research-research-program-js" */),
  "component---src-templates-tracking-index-js": () => import("./../../../src/templates/tracking/index.js" /* webpackChunkName: "component---src-templates-tracking-index-js" */)
}

