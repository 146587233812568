export const isLast = (index, arr) => index === arr.length - 1
import {
	fromPairs,
	toPairs,
	addIndex,
	map,
	compose,
	filter,
	reduce,
	splitEvery,
	groupBy,
	prop,
	sortBy
} from 'ramda'

export const kebabCase = (string = '') =>
	string
		.replace(/([a-z])([A-Z])/g, '$1-$2')
		.replace(/\s+/g, '-')
		.toLowerCase()

const mapIndex = addIndex(map)

const removeEmptyValues = filter(({ type, id, ...rest }) => rest && type)

const mapImagePropsAgainstMedia = media =>
	map(([key, value]) => {
		// images
		if (
			(key.includes('image') ||
				key.includes('asset') ||
				key.includes('logo') ||
				key.includes('icon') || 
				key.includes('video') || 
				key.includes('mm_video') || 
				key.includes('tout_video')) &&
			typeof value === 'number' &&
			value > 2000
		) {
			return [key, media[value]]
		}
		if (key === 'gallery' && value.length) {
			return [key, map(image => media[image])(value)]
		}

		// links
		if (
			typeof value === 'string' &&
			value.startsWith(process.env.REACT_OLD_API_BASE)
		) {
			console.warn(`found link with a silly path, id`)
			return [key, value.split(process.env.REACT_OLD_API_BASE)[1]]
		}
		return [key, value]
	})

const normalizeKeys = map(([key, value]) => [key.toLowerCase(), value])

const removeObjectsWithOutValues = filter(([, value]) => value)

const mapBlocksAndCore = reduce((acc, block) => {
	const { innerBlocks, innerHTML, attrs, blockName } = block
	const embed = blockName && blockName.includes('embed')

	const textContent = innerHTML && innerHTML.trim()

	let node = textContent && {
		name: blockName,
		data: {
			content: textContent
		}
	}

	if (embed) {
		const [, type] = blockName.split('/')
		node = {
			name: 'core-embed',
			data: {
				content: textContent,
				provider: type
			}
		}
	}

	if (innerBlocks && innerBlocks.length) {
		return [
			...acc,
			node,
			...innerBlocks.map((inner, i) => ({
				name: inner.blockName,
				data: {
					content: inner.innerHTML,
					blocks:
						inner.innerBlocks &&
						inner.innerBlocks.map((inner2, j) => ({
							name: inner2.blockName,
							content: inner2.innerHTML,
							id: `${inner2.blockName}-${i}${j}`
						}))
				}
			}))
		].filter(Boolean)
	}

	return [...acc, node, attrs]
}, [])

const removeBlocksWithOutNames = filter(
	block => block.attrs.name || block.blockName
)

const cleanBlocks = media =>
	mapIndex((block, index) => ({
		type: block.name,
		id: block.wordpress_id || `${block.name}_${index}`,
		...compose(
			fromPairs,
			mapImagePropsAgainstMedia(media),
			normalizeKeys,
			removeObjectsWithOutValues,
			toPairs
		)(block.data)
	}))

const addPosition = mapIndex((block, index) => ({ ...block, position: index }))

const createColumn = columns =>
	mapIndex((item, index) => ({
		type: columns,
		blocks: item,
		position: item[0].position,
		id: `${index}-${columns}`
	}))

const mapRepeaterKeys = ({ name, keys, data, index }) => {
	return keys.reduce((acc, curr) => {
		return { ...acc, [curr]: data[`${name}_${index}_${curr}`] }
	}, {})
}

const transformRepeaters = map(item => {
	if (item.type === 'acf/simple-slider') {
		const slides = Array.from({ length: item.slides }, (_, i) => {
			return mapRepeaterKeys({
				name: 'slides',
				keys: ['copy', 'cta_link', 'cta_text', 'title', 'image'],
				data: item,
				index: i,
				id: `slides_${i}`
			})
		})

		return { ...item, slides }
	}

	if (item.type === 'acf/use-case-card-slider') {
		const { use_cases } = item
		const slides = Array.from({ length: use_cases }, (_, i) => {
			return mapRepeaterKeys({
				name: 'use_cases',
				keys: ['copy', 'ctalink', 'ctatext', 'heading', 'title', 'image'],
				data: item,
				index: i,
				id: `use_cases_${i}`
			})
		})

		return { ...item, slides }
	}

	if (item.type === 'acf/icon-block') {
		const { icon_set } = item
		const icons = Array.from({ length: icon_set }, (_, i) => {
			return mapRepeaterKeys({
				name: 'icon_set',
				keys: ['icon_copy', 'icon_asset', 'icon_link'],
				data: item,
				index: i,
				id: `icon_set_${i}`
			})
		})

		return { ...item, icons }
	}

	if (item.type === 'acf/tout-block') {
		const { extra_links } = item
		const links = Array.from({ length: extra_links }, (_, i) => {
			return mapRepeaterKeys({
				name: 'extra_links',
				keys: ['tout_cta_label', 'tout_cta_link'],
				data: item,
				index: i,
				id: `extra_links_${i}`
			})
		})

		return { ...item, links }
	}

	if (item.type === 'acf/product-tout-block') {
		const { products } = item
		const remainingItems = Array.from({ length: products }, (_, i) => {
			return mapRepeaterKeys({
				name: 'products',
				keys: ['header', 'subheading', 'intro', 'image', 'ctaonelink', 'ctaonecopy', 'ctatwolink', 'ctatwocopy'],
				data: item,
				index: i,
				id: `products_${i}`
			})
		})

		return { ...item, remainingItems }
	}

	if (item.type === 'acf/licensing-table') {
		const { licensing_table } = item
		const columns = Array.from({ length: licensing_table }, (_, i) => {
			return mapRepeaterKeys({
				name: 'licensing_table',
				keys: ['heading', 'subheading', 'cta_text', 'cta_link', 'cta_colour', 'eligibility', 'training', 'engineering', 'design_and_integration', 'public', 'resellable', 'use_of_logo', 'white_label', 'additional_resources', 'priority_access'],
				data: item,
				index: i,
				id: `licensing_table_${i}`
			})
		})

		return { ...item, columns }
	}

	if (item.type === 'acf/tabs') {
		const tabs = Array.from({ length: item.tabs }, (_, i) => {
			return mapRepeaterKeys({
				name: 'tabs',
				keys: ['tab_label', 'tab_page'],
				data: item,
				index: i,
				id: `tabs_${i}`
			})
		})

		return { ...item, tabs }
	}

	if (item.type === 'acf/logos-block') {
		const logos = Array.from({ length: item.logos }, (_, i) => {
			return mapRepeaterKeys({
				name: 'logos',
				keys: ['logo', 'link'],
				data: item,
				index: i,
				id: `logos_${i}`
			})
		})

		return { ...item, logos }
	}

	if (item.type === 'acf/where-to-buy') {
		const countries = Array.from({ length: item.countries }, (_, i) => {
			const country = mapRepeaterKeys({
				name: 'countries',
				keys: ['name', 'price_from', 'retailers'],
				data: item,
				index: i,
				id: `countries_${i}`
			})
			const retailers = Array.from({ length: country.retailers }, (_, index) => {
				return mapRepeaterKeys({
					name: `countries_${i}_retailers`,
					keys: ['retailer'],
					data: item,
					index
				})
			})
			return { ...country, retailers }

		})

		return { ...item, countries }
    }
    
    if (item.type === 'acf/columns-block') {
		const columns = Array.from({ length: item.columns }, (_, i) => {
			return mapRepeaterKeys({
				name: 'columns',
				keys: ['image', 'copy', 'cta_text', 'cta_link', 'image_style'],
				data: item,
				index: i,
				id: `columns_${i}`
			})
		})

		return { ...item, columns }
	}

    if (item.type === 'acf/options-block') {
		const options = Array.from({ length: item.options }, (_, i) => {
			return mapRepeaterKeys({
				name: 'options',
				keys: ['link', 'link_colour', 'type', 'text_colour'],
				data: item,
				index: i,
				id: `options_${i}`
			})
		})

		return { ...item, options }
	}

	if (item.type === 'acf/image-video-hero' || item.type === 'acf/ctas-block') {
		const ctas = Array.from({ length: item.ctas }, (_, i) => {
			return mapRepeaterKeys({
				name: 'ctas',
				keys: ['cta_text', 'cta_link', 'cta_colour', 'cta_text_colour', 'cta_background_colour'],
				data: item,
				index: i,
				id: `ctas_${i}`
			})
		})

		return { ...item, ctas }
	}

	if (item.type === 'acf/testimonials') {
		const testimonials = Array.from({ length: item.testimonials }, (_, i) => {
			return mapRepeaterKeys({
				name: 'testimonials',
				keys: ['image', 'text', 'author', 'link'],
				data: item,
				index: i,
				id: `testimonials_${i}`
			})
		})

		return { ...item, testimonials }
	}

	if (item.type === 'acf/panels-block') {
		const panels = Array.from({ length: item.panels }, (_, i) => {
			return mapRepeaterKeys({
				name: 'panels',
				keys: ['icon', 'title', 'copy'],
				data: item,
				index: i,
				id: `panels_${i}`
			})
		})

		return { ...item, panels }
	}

	return item
})

export const cleanProps = (blocks, media) => {
	const columns = 'core/column'
	const images = JSON.parse(media)

	const props = compose(
		addPosition,
		transformRepeaters,
		removeEmptyValues,
		cleanBlocks(images),
		filter(Boolean),
		mapBlocksAndCore,
		removeBlocksWithOutNames
	)(blocks)

	const hasCoreColumns = props.some(block => block.type === columns)

	if (hasCoreColumns) {
		const coreProps = compose(
			createColumn(columns),
			splitEvery(2),
			prop(columns),
			groupBy(block => block.type)
		)(props)
		const otherProps = filter(block => block.type !== columns, props)
		const propsWithCore = sortBy(block => block.position)([
			...otherProps,
			...coreProps
		])

		return propsWithCore
	}

	return props
}

/**
 *
 * this is the same as the cleanProps function, but also handles mapping related content
 * to block types
 *
 * @param {array} blocks
 * @param {object} media
 * @param {object} relations
 * @param {boolean} match
 * @return {function}
 */
export const cleanPropsWithRelations = (
	blocks,
	media,
	relations,
	match = true
) => {
	const images = JSON.parse(media)
	const mapItem = item => {
		if (item && item.blocks) {
			const hasFeatured = item.featured_image_url
			return {
				...item,
				featured_image_url:
					hasFeatured && images[item.featured_image_url.wordpress_id],
				blocks: item.blocks ? cleanProps(item.blocks, media) : null
			}
		}

		return item
	}

	return cleanProps(blocks, media).map(node => {
		if (relations[node.type]) {
			const { key, data } = relations[node.type]

			if (!match) {
				return {
					...node,
					related: data.map(mapItem)
				}
			}

			return {
				...node,
				related:
					node[key] &&
					node[key]
						.map(id =>
							data.find(item => item.wordpress_id === parseInt(id, 10))
						)
						.filter(Boolean)
						.map(mapItem)
			}
		}
		return node
	})
}

export function debounce(func, wait, immediate) {
	let timeout
	return function(...args) {
		const context = this
		const later = function() {
			timeout = null
			if (!immediate) func.apply(context, args)
		}
		const callNow = immediate && !timeout
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
		if (callNow) func.apply(context, args)
	}
}

/**
 * Remove slashes from the start and end of a string
 * @param {string} str
 * @return {string}
 */
export function stripSlashes(str = '') {
	return str.replace(/(^\/+|\/+$)/g, '')
}
const mapFilter = addIndex(filter)

/**
 * @param {string} name
 * @param {number} numberOfPropsPerItem
 * @return {function}
 */
export const mapSlideItems = (name, numberOfPropsPerItem = 6) =>
	compose(
		map(reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})),
		splitEvery(numberOfPropsPerItem),
		map(([key, value]) => [key.replace(/[0-9]/g, '').split('__')[1], value]),
		mapFilter(([key]) => key.includes(name)),
		Object.entries
	)

/**
 * Get list of years from 2018 until now
 * @return {array} years
 */

export const getYearsArray = (start) => {
	let years = []
	let year = start
	const thisYear = new Date().getFullYear()

	while(year < thisYear) {
		year += 1
		years = [...years, year]
	}
	
	return years;
}

/**
 * If #form block exists, scroll to it
 */

export const scrollToForm = () => {
	const form = document.querySelector('#form-block')
	if(form) window.scrollTo({top: (form.offsetTop - 100), behavior: 'smooth'})
}